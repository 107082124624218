const trimString = {
  methods: {
    // eslint-disable-next-line no-unused-vars
    trimString(value) {
      if (value && value.length > 0) {
        return value.trim()
      } else return ''
    }
  }
}

export default trimString;
